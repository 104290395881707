export async function track(
  eventName: string,
  properties?: { [id: string]: string | null },
) {
  const { track } = await import('./EventStateInternal');
  track(eventName, properties);
}

export async function identify(userId: string): Promise<void> {
  const { identify } = await import('./EventStateInternal');
  await identify(userId);
}
