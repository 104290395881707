import { call, track } from 'client/frontend/Socket';
import { DBObject } from 'shared/DB';
import { insertSorted, isDefined, UnsubscribeT } from 'shared/Helper';
import {
  StoreGetDocInput,
  StoreGetDocOutput,
  StoreGetDocsInput,
  StoreGetDocsOutput,
  StoreTrackDocInput,
  StoreTrackDocOutput,
  StoreTrackDocsInput,
  StoreTrackDocsOutput,
} from 'shared/Store';
import { Socket } from 'socket.io-client';

export type DocResult<T> = (x: T) => void;
export type DocOrNullResult<T> = (x: T | null) => void;
export type DocsResult<T> = (x: T[]) => void;

export async function getDoc<T extends DBObject>(
  socket: Socket,
  input: StoreGetDocInput,
): Promise<T | null> {
  return (
    await call<StoreGetDocInput, StoreGetDocOutput>(
      socket,
      'storeGetDoc',
      input,
    )
  ).item as T | null;
}

export async function getDocs<T extends DBObject>(
  socket: Socket,
  input: StoreGetDocsInput,
): Promise<T[]> {
  return (
    await call<StoreGetDocsInput, StoreGetDocsOutput>(
      socket,
      'storeGetDocs',
      input,
    )
  ).items as T[];
}

export function trackDoc<T extends DBObject>(
  socket: Socket,
  input: StoreTrackDocInput,
  cb: DocResult<T>,
): UnsubscribeT {
  return track<StoreTrackDocInput, StoreTrackDocOutput>(
    socket,
    'storeTrackDoc',
    input,
    (x) => {
      if (!isDefined(x.item)) {
        return;
      }
      cb(x.item as T);
    },
  );
}

export function trackDocOrNull<T extends DBObject>(
  socket: Socket,
  input: StoreTrackDocInput,
  cb: DocOrNullResult<T>,
): UnsubscribeT {
  return track<StoreTrackDocInput, StoreTrackDocOutput>(
    socket,
    'storeTrackDoc',
    input,
    (x) => {
      cb(x.item as T | null);
    },
  );
}

export function trackDocs<T extends DBObject>(
  socket: Socket,
  input: StoreTrackDocsInput,
  cb: DocsResult<T>,
): UnsubscribeT {
  let items: DBObject[] | null = null;
  return track<StoreTrackDocsInput, StoreTrackDocsOutput>(
    socket,
    'storeTrackDocs',
    input,
    (x) => {
      if (x.type == 'initial') {
        items = x.items;
      } else {
        if (isDefined(items)) {
          items = insertSorted(
            items.filter((y) => y.id != x.item.id),
            (a, b) => b.created - a.created,
            'replace',
            x.item,
          );
        }
      }

      if (isDefined(items)) {
        cb([...items] as T[]);
      }
    },
  );
}
