import { DBObject } from './DB';

export const defaultVoiceId = 'google-male';

export interface Voice extends DBObject {
  name: string;
  description: string;
  hidden: boolean;
  featured: boolean;
  mode: 'public' | 'private';
}

export interface VoiceSearchInput {
  search: string;
  limit: number;
}

export interface VoiceSearchOutput {
  items: Voice[];
}

export type VoiceFunctionsT = 'voiceSync' | 'voiceSearch';
