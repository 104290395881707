import { DBObject } from './DB';
import { FieldValueT, isStringEmpty } from './Helper';
import { PageT } from './Pages';

export interface QueryT {
  [id: string]: string | null;
}

export type NotificationType =
  | 'message'
  | 'post'
  | 'friendAdd'
  | 'friendRequest'
  | 'messageReact';

export interface Notification extends DBObject {
  type: NotificationType;
  userId: string;
  conversationId: string | null;
  read: number | null;
  emailed: number | null;
  title: string;
  text: string;
  page: PageT;
  query: QueryT;
  imageUrl?: string | null | undefined;
  addToCount: boolean;
}

export interface PayloadData {
  uri: string;
  page: PageT;
  query: QueryT;
  [id: string]: FieldValueT;
}

export interface Payload {
  title: string;
  actions?: { action: string; title: string; icon: string }[]; // ignored on ios & firefox
  badge?: string; // ignored on ios
  body: string;
  data: PayloadData;
  icon?: string; // ignored on ios
  image?: string; // ignored on ios
  requireInteraction?: boolean;
  silent?: boolean;
  vibrate?: number; // ignored on ios & firefox
}

export function getQueryRequired(query: QueryT, id: string): string {
  if (isStringEmpty(query[id])) {
    throw new Error(`query missing ${id}`);
  }

  return query[id];
}

export function getQueryOptional(query: QueryT, id: string): string | null {
  if (isStringEmpty(query[id])) {
    return null;
  }

  return query[id];
}

export interface NotificationMarkReadInput {
  conversationId: string | null;
}

export type NotificationFunctionsT = 'notificationMarkRead';
