import axios from 'axios';
import { nanoid } from 'nanoid';
import {
  BackendFunctionsT,
  BackendRequestsT,
  BackendTracksT,
} from 'shared/Functions';
import { isDefined, UnsubscribeT } from 'shared/Helper';
import { io, Socket } from 'socket.io-client';
import { captureClientError } from './Error';

interface Subscription {
  op: BackendTracksT;
  input: object;
  cb: (x: object) => void;
}

const trackSubsciptions = new Map<string, Subscription>();

export function initSocket(token: string): Socket {
  const uri = `${window.location.protocol == 'http:' ? 'ws:' : 'wss:'}//${
    window.location.host
  }/`;
  console.log(`[SOCKET] created`, uri);
  const socket = io(uri, {
    auth: {
      token,
    },
  });

  socket.on('trackCallback', (x: { id: string; data: object }) => {
    trackSubsciptions.get(x.id)?.cb(x.data);
  });

  socket.io.on('reconnect', () => {
    console.error(`[SOCKET] reconnect`);
    for (const [id, sub] of trackSubsciptions) {
      console.log(`[SOCKET] track reconnect`, sub.op, sub.input);
      socket
        .emitWithAck('track', { id, op: sub.op, input: sub.input })
        .then((x: { error?: string | null }) => {
          if (isDefined(x.error)) {
            captureClientError(`[SOCKET] track error ${x.error}`, x.error);
          }
        })
        .catch((x: unknown) => {
          captureClientError(`[SOCKET] track error`, x);
        });
    }
  });

  return socket;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export async function call<Input, Output>(
  socket: Socket,
  op: BackendFunctionsT,
  input: Input,
  disableLog?: boolean,
): Promise<Output> {
  try {
    const start = Date.now();
    const output = (await socket
      .timeout(60000)
      .emitWithAck('call', { op, input })) as { data?: Output; error?: string };
    if (isDefined(output.error) || !isDefined(output.data)) {
      captureClientError(
        `[SOCKET] CALL ${Date.now() - start}ms ${op} ${JSON.stringify(input)}`,
        output.error,
      );
      throw new Error(output.error);
    }
    if (!disableLog) {
      console.log(
        `[SOCKET] call ${Date.now() - start}ms`,
        op,
        input,
        output.data,
      );
    }
    return output.data;
  } catch (e) {
    const error = e as Error;
    captureClientError(`[SOCKET] CALL ${op} ${JSON.stringify(input)}`, error);
    throw error;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function track<Input extends object, Output>(
  socket: Socket,
  op: BackendTracksT,
  input: Input,
  cb: (x: Output) => void,
): UnsubscribeT {
  try {
    console.log(`[SOCKET] track`, op, input);
    const id = nanoid();
    const start = Date.now();
    trackSubsciptions.set(id, {
      op,
      input,
      cb: (output: object) => {
        console.log(
          `[SOCKET] track output ${Date.now() - start}ms`,
          op,
          input,
          output,
        );
        cb(output as Output);
      },
    });
    socket
      .emitWithAck('track', { id, op, input })
      .then((x: { error?: string | null }) => {
        if (isDefined(x.error)) {
          captureClientError(`[SOCKET] track error ${x.error}`, x.error);
        }
      })
      .catch((x: unknown) => {
        captureClientError(`[SOCKET] track error`, x);
      });

    return () => {
      trackSubsciptions.delete(id);
      console.log(`[SOCKET] untrack`, op);
      socket.emit('untrack', { id });
    };
  } catch (e) {
    const error = e as Error;
    captureClientError(`[SOCKET] track ${op} ${JSON.stringify(input)}`, error);
    throw error;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export async function request<Input, Output>(
  op: BackendRequestsT,
  input: Input,
): Promise<Output> {
  try {
    const start = Date.now();
    const result = await axios.post<Output>(`/request`, { op, input });
    console.log(
      `[REQUEST] CALL ${Date.now() - start}ms`,
      op,
      input,
      result.data,
    );
    return result.data;
  } catch (e) {
    const error = e as Error;
    captureClientError(`[SOCKET] CALL ${op} ${JSON.stringify(input)}`, error);
    throw error;
  }
}
