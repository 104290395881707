import { StringTable } from 'shared/lang/StringTable';
import { DBObject } from './DB';
import { isDefined, isStringEmpty, MeasurementSystem } from './Helper';
import { ImageUriResult } from './Image';
import { defaultGlobalId } from './UserPublic';

export type UserAppRole = 'user' | 'admin' | 'mod' | 'banned';

// export const cookieTheme = 'SOCIABLE_THEME';

export type Theme = 'light' | 'dark';

export const defaultTheme: Theme = 'light';

export const themes: Theme[] = ['light', 'dark'];

export const themesSet = new Set(themes);

export const themeNames: Record<Theme, string> = {
  light: 'Light',
  dark: 'Dark',
};

export interface PhoneData extends DBObject {
  userId: string | null;
  code: string;
  expiration: number | null;
}

export interface EmailData extends DBObject {
  userId: string;
  passwordHash: string | null;
}

export interface PushSubscription {
  endpoint: string;
  expirationTime?: null | number;
  keys: {
    p256dh: string;
    auth: string;
  };
}

export type UserBannedReason =
  | 'bannedAdvertising'
  | 'bannedInauthentic'
  | 'bannedSexual'
  | 'bannedUnderage'
  | 'bannedIllegal';

export const userBannedReasons: UserBannedReason[] = [
  'bannedAdvertising',
  'bannedInauthentic',
  'bannedSexual',
  'bannedUnderage',
  'bannedIllegal',
];

export function userBannedReasonName(
  x: UserBannedReason,
  strings: StringTable,
) {
  return strings[x];
}

// export type UserInviteStatus = 'requested' | 'approved';

// Switch to null if you want to require email & invite for all new users
// Switch to 'requested' if you want to invite (but not email) for all new users
// export const defaultInviteStatus: UserInviteStatus | null = 'approved';

export const defaultBillingMultiplier = 2;
export const defaultBillingPermanentCredit = 5_000_000_000;
export const defaultBillingAnonymousCredit = 0;

export function userPrivateBalance(userPrivate: UserPrivate) {
  if (userPrivate.id == defaultGlobalId) {
    return defaultBillingPermanentCredit;
  }

  return (
    userPrivate.billingBalance +
    userPrivate.billingMonthlyCredit +
    userPrivate.billingPermanentCredit
  );
}

export function getAnonymous(authUser: {
  email?: string | null;
  phoneNumber?: string | null;
}) {
  return isStringEmpty(authUser.email) && isStringEmpty(authUser.phoneNumber);
}

export function getUserPrivateLoading(
  userId: string,
  anonymous: boolean,
  theme: Theme | null,
): UserPrivate {
  const user: UserPrivate = {
    id: userId,
    version: 0,
    created: 0,
    updated: 0,
    role: 'user',
    subscriptions: [],
    units: 'IMPERIAL',
    phone: null,
    email: '',
    emailVerified: true,
    emailContactAllowed: null,
    emailVerificationCode: null,
    lastLogin: 0,
    theme,
    anonymous: anonymous,
    billingBalanceUpdateScheduled: 0,
    billingBalance: 0,
    billingLastUpdate: 0,
    billingPermanentCredit: defaultBillingPermanentCredit,
    billingMonthlyCredit: 0,
    stripeCustomerId: null,
    bannedReason: null,
    pinnedBotIds: [],
    pinnedConversationIds: [],
    forceCheapVoices: false,
    notificationEmailed: 0,
    notificationCount: 0,
    lastPost: 0,
  };
  return user;
}

export interface UserPrivate extends DBObject {
  role: UserAppRole;
  subscriptions?: PushSubscription[];
  units: MeasurementSystem;
  notificationEmailed: number;
  phone: string | null;
  email: string | null;
  emailVerified: boolean | null;
  emailContactAllowed: boolean | null;
  emailVerificationCode: string | null;
  lastLogin: number;
  theme: Theme | null;
  anonymous: boolean;
  pinnedConversationIds: string[];
  pinnedBotIds: string[];
  forceCheapVoices: boolean;
  billingBalanceUpdateScheduled: number;
  billingBalance: number;
  billingLastUpdate: number;
  billingPermanentCredit: number;
  billingMonthlyCredit: number;
  stripeCustomerId: string | null;
  bannedReason: UserBannedReason | null;
  notificationCount: number;
  lastPost: number;
}

export interface ReadyPlayerMe extends DBObject {
  readyPlayerMeUserId: string;
}

export interface PromoCode extends DBObject {
  userId: string | null;
  freeDays: number;
}

export interface UserPrivateSetFieldsInput {
  fields: Partial<UserPrivate>;
}

export interface UserPrivateAdminGetInput {
  userId: string;
}

export interface UserPrivateAdminGetOutput {
  userPrivate: UserPrivate;
}

export interface UserPrivateAddSubscriptionInput {
  subscription: PushSubscription;
}

export interface UserPrivateBanInput {
  userId: string;
  bannedReason: UserBannedReason;
}

export interface UserPrivateUnbanInput {
  userId: string;
}

export interface UserPrivateSetVerificationImageInput {
  type: 'face' | 'age';
  image: ImageUriResult;
}

export interface UserPrivateSendFreeSubscriptionInput {
  freeUserId: string;
  freeDays: number;
}

export interface UserPrivateCreateOfferInput {
  freeDays: number;
}

export interface UserPrivateCreateOfferOutput {
  id: string;
}

export interface UserPrivateRedeemOfferInput {
  id: string;
}

export interface UserPrivateReadyPlayerMeTokenInput {
  userId: string;
}

export interface UserPrivateReadyPlayerMeTokenOutput {
  token: string;
}

export interface UserPrivateGetBalanceInput {
  userId: string;
}

export interface UserPrivateGetBalanceOutput {
  balance: number;
}

export interface UserPrivateBadgeCountClearInput {
  conversationId: string | null;
}

export function getEmailVerification(userPrivate: UserPrivate | null): boolean {
  if (!isDefined(userPrivate)) {
    return false;
  }

  if (invalidPhoneNumber(userPrivate) && unverifiedEmail(userPrivate)) {
    return false;
  }
  return true;
}

function invalidPhoneNumber(userPrivate: UserPrivate): boolean {
  return !isDefined(userPrivate.phone) || userPrivate.phone.length <= 0;
}

function unverifiedEmail(userPrivate: UserPrivate): boolean {
  const emailVerified = userPrivate.emailVerified ?? false;
  return userPrivate.email === null || !emailVerified;
}

export type UserPrivateFunctionsT =
  | 'userPrivateAddSubscription'
  | 'userPrivateAdminGet'
  | 'userPrivateUpdate'
  | 'userPrivateBan'
  | 'userPrivateUnban'
  | 'userPrivateReadyPlayerMeToken'
  | 'userPrivateGetBalance'
  | 'userPrivateBadgeCountClear';
