import { track } from 'client/common/provider/EventState';
import { QueryT } from 'shared/Notification';

export type AnalyticEvent =
  | 'APP_OPEN'
  | 'DEFERRED_LINK'
  | 'LOGIN_DONE'
  | 'ONBOARDING_DONE'
  | 'MESSAGE_SEND_FRIEND'
  | 'MESSAGE_SEND_GROUP'
  | 'MESSAGE_SEND_AI'
  | 'NOTIF_OPEN'
  | 'PURCHASE_SKIP'
  | 'PURCHASE_BOOST'
  | 'PURCHASE_SUBSCRIPTION'
  | 'PURCHASE_SUPERLIKE'
  | 'PURCHASE_MATCHES'
  | 'REC_BOOST'
  | 'USER_ADD_IMAGE'
  | 'USER_DISLIKE'
  | 'USER_UNMATCH'
  | 'USER_LIKE'
  | 'USER_MATCH'
  | 'USER_PICK_LIMIT_EXCEEDED'
  | 'USER_SUPERLIKE'
  | 'RATE_APP_YES'
  | 'RATE_APP_NO'
  | 'RATE_APP_FINISHED'
  | 'RATE_APP_FAILED'
  | 'PAGE_DURATION'
  | 'PAYWALL_INITIAL_OPEN'
  | 'PAYWALL_INITIAL_SKIP'
  | 'PAYWALL_INITIAL_SUBSCRIPTION'
  | 'PAYWALL_APPSTART_OPEN'
  | 'PAYWALL_APPSTART_SKIP'
  | 'PAYWALL_APPSTART_SUBSCRIPTION'
  | 'PAYWALL_SETTINGS_OPEN'
  | 'PAYWALL_SETTINGS_SKIP'
  | 'PAYWALL_SETTINGS_SUBSCRIPTION'
  | 'FIRST_MATCH'
  | 'CUSTOM_SCHOOL'
  | 'SHARE_LINK'
  | 'SHARE_CHAT'
  | 'SHARE_COPY'
  | 'SHARE_POST';

export function logEvent(
  event: AnalyticEvent,
  params?: { [id: string]: string },
) {
  console.log(`[EVENT] ${event}${params ? ' ' + JSON.stringify(params) : ''}`);
  track(event, params);
}

interface EventPageData {
  path: string;
  query: QueryT | undefined;
  start: number;
}

let currentPage: EventPageData | null = null;

export function logPage(path: string, query?: QueryT) {
  if (currentPage?.path == path) {
    return;
  }

  if (currentPage) {
    logEvent('PAGE_DURATION', {
      path: currentPage.path,
      ...currentPage.query,
      duration: (Date.now() - currentPage.start).toString(),
    });
  }

  logCustomEvent(`PAGE_${path.toUpperCase().replace('/', '_')}`, { ...query });

  logCustomEvent('PAGE_SHOW', {
    path: path,
    ...query,
  });
  currentPage = {
    path,
    query,
    start: Date.now(),
  };
}

let isAppActive = true;

export function logAppActive(isActive: boolean) {
  if (isActive != isAppActive) {
    isAppActive = isActive;
    if (!isAppActive) {
      if (currentPage) {
        logEvent('PAGE_DURATION', {
          path: currentPage.path,
          ...currentPage.query,
          duration: (Date.now() - currentPage.start).toString(),
        });
      }
    } else {
      if (currentPage) {
        logCustomEvent('PAGE_SHOW', {
          path: currentPage.path,
          ...currentPage.query,
        });
        currentPage.start = Date.now();
      }
    }
  }
}

export function logCustomEvent(
  event: string,
  params?: { [id: string]: string | null },
) {
  console.log(`[EVENT] ${event}${params ? ' ' + JSON.stringify(params) : ''}`);
  track(event, params);
}
