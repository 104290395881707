import { UserPrivate } from './UserPrivate';

export const useNativeDriver = true; // false;

export type BackendMode = 'prod' | 'dev' | 'emu';

export const debug = process.env['DEBUG'] == 'true';

export const backendMode = debug ? 'dev' : 'prod';

export const localhost = process.env['NODE_ENV'] == 'development';

console.error(`[DEBUG] ${backendMode} ${localhost}`);

export function isAdmin(userPrivate: UserPrivate | null) {
  return userPrivate?.role == 'admin';
}

export function isDebugOrAdmin(userPrivate: UserPrivate | null) {
  return debug || userPrivate?.role == 'admin';
}

export const websiteUrl = localhost
  ? debug
    ? 'http://localhost:3000/'
    : 'http://localhost:3999/'
  : debug
  ? 'https://staging.sociableai.co/'
  : 'https://app.sociableai.co/';
