if (typeof __DEV__ === 'undefined') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  global.__DEV__ = process.env.NODE_ENV !== 'production';
}

import { decodeJwt } from 'jose';
import { Colors } from 'shared/Colors';
import { isStringEmpty } from 'shared/Helper';
import { getQueryOptional, QueryT } from 'shared/Notification';
import { PageT } from 'shared/Pages';
import { defaultTheme } from 'shared/UserPrivate';
import { cookieToken, getCookie, setCookie } from './frontend/Cookie';
import { initSocket } from './frontend/Socket';
import { userAnonymousLogin } from './frontend/UserPublic';

async function start() {
  // const theme = getEnumFromString(
  //   getCookie(cookieTheme),
  //   themesSet,
  //   defaultTheme,
  // );

  let page: PageT = window.location.pathname.slice(1) as PageT;
  if (isStringEmpty(page)) {
    page = 'index';
  }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const query: QueryT = {};
  for (const [key, value] of urlParams) {
    query[key] = value;
  }

  const isEmbed = getQueryOptional(query, 'embed') == 'true';
  const colorsText = getQueryOptional(query, 'colors');
  const colors = colorsText
    ? (JSON.parse(decodeURIComponent(colorsText)) as Colors)
    : null;
  delete query['colors'];
  delete query['source'];
  delete query['embed'];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isIOS = window.isIOS as boolean;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isMobile = window.isMobile as boolean;

  const isDesktop = !isEmbed && !isMobile;

  let userId: string | null = null;
  let anonymous = true;
  let token = getCookie(cookieToken);
  if (!isStringEmpty(token)) {
    const payload = decodeJwt(token);
    userId = payload['userId']! as string;
    anonymous = payload['anonymous']! as boolean;
  } else {
    token = (await userAnonymousLogin()).token!;
    setCookie(cookieToken, token);
    const payload = decodeJwt(token);
    userId = payload['userId']! as string;
    anonymous = payload['anonymous']! as boolean;
  }

  const socket = token ? initSocket(token) : null;
  const { ClientInit } = await import('./clientInternal');
  ClientInit({
    socket,
    isDesktop,
    isEmbed,
    isIOS,
    colors,
    theme: defaultTheme, // theme,
    userId,
    anonymous,
    router: { target: { page, query } },
  });
}

if (process.env['NODE_ENV'] != 'production') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  module.hot.accept();
}

void start();
