import dayjs from 'dayjs';
import { StringTable } from 'shared/lang/StringTable';
import { isDefined } from './Helper';

export const oneDay = 24 * 60 * 60 * 1000;
export const oneHour = 60 * 60 * 1000;
export const oneMinute = 60 * 1000;
export const oneSecond = 1000;
export const dbDateFormat = 'YYYY-MM-DD';
export const userDateFormat = 'MMM D, YYYY';
export const dbDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export function dateToDayFormat(x: Date | number | null | undefined): string {
  if (!isDefined(x)) {
    return '';
  }
  return dayjs(x).format('MMM D, YYYY');
}

export function dateToMDY(x: Date | number | null | undefined): string {
  if (!isDefined(x)) {
    return '';
  }
  return new Date(x).toISOString().split('T')[0] ?? '';
}

export function dateToTimeFormat(x: Date | number | null | undefined): string {
  if (!isDefined(x)) {
    return '';
  }
  return dayjs(x).format('h:mm a');
}

// Force UTC to a range of -12 to 12, sorry about this Samoa
// https://en.wikipedia.org/wiki/ListOf_UTCOffsets
export const utcOffset = Math.max(
  -12,
  Math.min(12, Math.floor(dayjs().utcOffset() / 60)),
);

export function durationToClock(duration: number | null) {
  if (!isDefined(duration)) {
    return '--';
  }

  const hours = Math.floor(duration / oneHour);
  const minutes = Math.floor(duration / oneMinute) % 60;
  const seconds = Math.floor(duration / oneSecond) % 60;

  if (hours > 0) {
    const hoursString = hours.toString();
    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');
    return `${hoursString}:${minutesString}:${secondsString}`;
  } else {
    const minutesString = minutes.toString();
    const secondsString = seconds.toString().padStart(2, '0');
    return `${minutesString}:${secondsString}`;
  }
}

export function durationToString(
  strings: StringTable,
  duration: number | null,
  useLongWords: boolean,
  highPrecision: boolean,
): string {
  if (!isDefined(duration)) {
    return '--';
  }

  if (duration < oneMinute) {
    return 'Now';
  }

  const timeInSeconds = duration / 1000;
  if (timeInSeconds < 60) {
    const seconds = Math.max(0, Math.floor(timeInSeconds));
    return `${seconds}${
      useLongWords
        ? ' ' + (seconds == 1 ? strings.secondLong : strings.secondsLong)
        : strings.secondShort
    }`;
  }

  const timeInMinutes = duration / 1000 / 60;
  if (timeInMinutes < 60) {
    const minutes = Math.floor(timeInMinutes);
    const seconds = Math.floor(timeInSeconds % 60);
    if (highPrecision && seconds > 0) {
      return `${minutes}${
        useLongWords
          ? ' ' + (minutes == 1 ? strings.minuteLong : strings.minutesLong)
          : strings.minuteShort
      } ${seconds}${
        useLongWords
          ? ' ' + (seconds == 1 ? strings.secondLong : strings.secondsLong)
          : strings.secondShort
      }`;
    }
    return `${minutes}${
      useLongWords
        ? ' ' + (minutes == 1 ? strings.minuteLong : strings.minutesLong)
        : strings.minuteShort
    }`;
  }

  const timeInHours = timeInMinutes / 60;

  if (timeInHours < 24) {
    const hours = Math.floor(timeInHours);
    const minutes = Math.floor(timeInMinutes % 60);
    if (highPrecision && minutes > 0) {
      return `${hours}${
        useLongWords
          ? ' ' + (hours == 1 ? strings.hourLong : strings.hoursLong)
          : strings.hourShort
      } ${minutes}${
        useLongWords
          ? ' ' + (minutes == 1 ? strings.minuteLong : strings.minutesLong)
          : strings.minuteShort
      }`;
    }
    return `${hours}${
      useLongWords
        ? ' ' + (hours == 1 ? strings.hourLong : strings.hoursLong)
        : strings.hourShort
    }`;
  }

  const timeInDays = timeInHours / 24;
  const days = Math.floor(timeInDays);
  const hours = Math.floor(timeInHours % 24);
  if (highPrecision && hours > 0) {
    return `${days}${
      useLongWords
        ? ' ' + (days == 1 ? strings.dayLong : strings.daysLong)
        : strings.dayShort
    } ${hours}${
      useLongWords
        ? ' ' + (hours == 1 ? strings.hourLong : strings.hoursLong)
        : strings.hourShort
    }`;
  }

  return `${days}${
    useLongWords
      ? ' ' + (days == 1 ? strings.dayLong : strings.daysLong)
      : strings.dayShort
  }`;
}
